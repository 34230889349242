

























































































import SportangoWeekPicker from "@/components/Inputs/SportangoWeekPicker.vue";
import UsersAutoComplete from "@/components/Navigation/UsersAutoComplete.vue";
import PaymentStatus from "@/components/Payments/PaymentStatus.vue";
import TablePaymentMethod from "@/components/Payments/TablePaymentMethod.vue";
import { WatchLoading } from "@/decorators/Loading";
import { DB } from "@/firebase";
import { CoachAdmin } from "@/mixins/CoachAdmin";
import { CurrentUserMixin, LoadingMixin } from "@/mixins/Helpers";
import { SharedPaymentIntents, SharedPaymentMethods } from "@/mixins/Payments";
import { getEventsQuery } from "@/store/actions/events";
import { PrivateLessonPaymentViewItem } from "@/types/Payment";
import { Header } from "@/types/Table";
import { calculatePrivateLessonDuration, fromFirestore } from "@/utils/parser";
import {
  getCorrectStatusAndMessage,
  getRightConvenienceFee,
  getRightPaymentMethod,
  parsePaymentDate
} from "@/utils/payments";
import {
  collection,
  onSnapshot,
  query,
  QueryConstraint,
  QuerySnapshot,
  where
} from "@firebase/firestore";
import { Unsubscribe } from "@firebase/util";
import {
  BaseUser,
  Event,
  EVENTS_TABLE_NAME,
  StripeMerchantInfo,
  TransactionCustomer
} from "@sportango/backend";
import dayjs from "dayjs";
import Component, { mixins } from "vue-class-component";
import { Watch } from "vue-property-decorator";

@Component({
  name: "private-payments-report",
  components: {
    UsersAutoComplete,
    SportangoWeekPicker,
    PaymentStatus,
    TablePaymentMethod
  }
})
export default class PrivatePaymentsReport extends mixins(
  LoadingMixin,
  CurrentUserMixin,
  SharedPaymentIntents,
  SharedPaymentMethods,
  CoachAdmin
) {
  isLoading = true;
  eventSubscription: Unsubscribe | null = null;
  startDate: Date = new Date();
  selectedCoach = "";
  selectedPlayer = "";

  get headers(): Array<Header<PrivateLessonPaymentViewItem>> {
    return [
      {
        value: "customerName",
        text: "Player"
      },
      {
        value: "lessonDate",
        text: "Lesson Date",
        sortable: true,
        width: "130px"
      },
      {
        value: "duration",
        text: "Duration",
        sortable: true,
        align: "center",
        width: "120px"
      },
      {
        value: "amount",
        text: "Amount",
        align: "center",
        sortable: true,
        width: "150px"
      },
      {
        value: "convenienceFee",
        text: "Fee",
        sortable: false,
        align: "center"
      },
      {
        value: "total",
        text: "Total",
        sortable: false,
        align: "center"
      },
      {
        value: "status",
        text: "Status",
        align: "center",
        sortable: false
      },
      {
        value: "paymentMethod",
        text: "Method",
        sortable: false,
        align: "center",
        width: "100px"
      },
      {
        value: "paymentDateShort",
        text: "Payment Date",
        align: "center",
        sortable: true
      }
    ];
  }

  get items(): Array<PrivateLessonPaymentViewItem> {
    const result: Array<PrivateLessonPaymentViewItem> = [];
    this.eventPlayers.forEach((p) => {
      const transaction = this.$store.getters.transactions.find(
        (t) => t.parentItem === p.event.id
      );
      if (transaction) {
        const transactionCustomer = transaction?.customers?.find(
          (c) => c.uid === p.uid
        );
        const paymentIntent = this.paymentIntents.find(
          (pI) => pI.id === transactionCustomer?.paymentIntentId
        );
        const { status, statusMessage } = getCorrectStatusAndMessage(
          transactionCustomer,
          paymentIntent
        );
        if (status !== "notStarted") {
          const { short, full } = parsePaymentDate(
            paymentIntent?.created,
            transactionCustomer
          );
          const paymentMethod =
            this.paymentMethods.find(
              (pM) => pM.id === getRightPaymentMethod(paymentIntent || null)
            ) || null;
          const defaultPaymentMethod =
            this.defaultPaymentMethods.find(
              (d) => d.customer === p.stripeCustomerId
            ) || null;
          const tableItem: PrivateLessonPaymentViewItem = {
            customerName: p.displayName || p.email || "",
            amount: this.calculateAmountForPlayer(
              p.event,
              transactionCustomer,
              p.uid
            ),
            convenienceFee: 0,
            total: this.calculateAmountForPlayer(
              p.event,
              transactionCustomer,
              p.uid
            ),
            paymentMethod: paymentMethod || defaultPaymentMethod || null,
            status,
            statusMessage,
            paymentDateShort: short,
            paymentDate: full,
            uid: p.uid,
            disabled: true,
            stripeCustomerId: p.stripeCustomerId,
            isPaymentRunning: (() => {
              if (transactionCustomer) {
                if (transactionCustomer.paidInCash) {
                  return false;
                }
                if (paymentIntent === undefined) {
                  switch (transactionCustomer.status) {
                    case "success":
                    case "failed":
                    case "missingStripeCustomerId":
                    case "missingPaymentMethod":
                    case "cancelled":
                    case "requiresAttention":
                    case "notStarted":
                      return false;
                    case "processing":
                      return true;
                    default:
                      return false;
                  }
                }
              }
              return false;
            })(),
            date: paymentIntent?.created || 0,
            defaultPaymentMethod: defaultPaymentMethod,
            itemId: `${p.uid}-${p.event.id}`,
            paidInCash: transactionCustomer?.paidInCash,
            lessonDate: dayjs(p.event.startTime).format("MM/DD/YYYY"),
            lessonDateNumber: p.event.startTime ? Number(p.event.startTime) : 0,
            duration: calculatePrivateLessonDuration(p.event),
            transaction,
            event: p.event
          };
          tableItem.convenienceFee = getRightConvenienceFee(
            tableItem.status,
            tableItem.amount,
            tableItem.defaultPaymentMethod,
            transactionCustomer,
            this.$store.getters.merchantInfo
          );
          tableItem.total = tableItem.amount + tableItem.convenienceFee;
          result.push(tableItem);
        }
      }
    });
    return result.sort((a, b) => b.lessonDateNumber - a.lessonDateNumber);
  }

  get eventPlayers(): Array<BaseUser & { event: Event }> {
    const results: Array<BaseUser & { event: Event }> = [];
    this.$store.getters.events.forEach((e) => {
      e.players?.forEach((p) => {
        const playerInfo = this.$store.getters.users.find(
          (u) => p.uid === u.uid
        );
        if (playerInfo) {
          if (this.playerToFilter) {
            if (playerInfo.uid !== this.playerToFilter) {
              return;
            }
          }
          results.push({
            ...playerInfo,
            event: e
          });
        }
      });
    });
    return results;
  }

  get merchantInfo(): StripeMerchantInfo | undefined {
    return this.$store.getters.merchantInfo;
  }

  get coachToFilter(): string | null {
    if (this.currentUser?.permissions.hasAdminAccess) {
      if (this.selectedCoach && this.selectedCoach.length > 0) {
        return this.selectedCoach;
      }
    } else if (this.currentUser?.permissions.hasCoachAccess) {
      return this.currentUser.uid;
    }
    return null;
  }

  get playerToFilter(): string | null {
    if (
      this.currentUser?.permissions.hasAdminAccess ||
      this.currentUser?.permissions.hasCoachAccess
    ) {
      if (this.selectedPlayer && this.selectedPlayer.length > 0) {
        return this.selectedPlayer;
      }
    } else if (this.currentUser?.permissions.hasPlayerAccess) {
      return this.currentUser.uid;
    }
    return null;
  }

  get noDataMessage(): string {
    if (this.coachToFilter) {
      return "No private lessons found";
    } else {
      return "Please select a coach";
    }
  }

  calculateAmountForPlayer(
    event: Event,
    transactionCustomer?: TransactionCustomer,
    playerId?: string
  ): number {
    if (transactionCustomer) {
      const transactionCust = transactionCustomer;
      if (transactionCust.amount) {
        return Number(transactionCust.amount);
      }
    }
    if (playerId) {
      const playerEventPrice = event.players?.find(
        (p) => p.uid === playerId
      )?.individualPrice;
      if (playerEventPrice) {
        return Number(playerEventPrice);
      }
    }
    if (event.price) {
      return Number(event.price);
    }
    return 0;
  }

  async mounted() {
    this.startEventWatch();
    this.$store.commit("events", []);
    if (this.isCurrentUserOnlyCoach) {
      await this.$store.dispatch("getCurrentCoachPlayers");
    }
  }

  @Watch("merchantInfo")
  @Watch("startDate")
  @Watch("coachToFilter")
  @Watch("playerToFilter")
  startEventWatch() {
    if (this.eventSubscription) {
      this.eventSubscription();
    }
    const queries: QueryConstraint[] = [
      where("confirmed", "==", true),
      where("eventType", "==", "PRIVATE_LESSON"),
      ...getEventsQuery({
        type: "week",
        refDate: this.startDate.toISOString()
      })
    ];
    if (this.coachToFilter) {
      queries.push(where("coaches", "array-contains", this.coachToFilter));
    }
    this.eventSubscription = onSnapshot(
      query(collection(DB, EVENTS_TABLE_NAME), ...queries),
      this.watchEvents
    );
  }

  @WatchLoading()
  async watchEvents({ docs }: QuerySnapshot) {
    this.$store.commit(
      "events",
      docs.map((d) => fromFirestore<Event>(d, "id"))
    );
    this.$store.commit("transactions", []);
    await this.$store.dispatch(
      "getTransactionsForParentItems",
      this.$store.getters.events.map((e) => e.id)
    );
    let players: string[] = [];
    this.$store.getters.events.forEach((e) => {
      players = [...players, ...(e.playerIds || [])];
    });
    await this.$store.dispatch("getUsersById", players);
    if (this.merchantInfo?.merchantId) {
      await this.getDefaultPaymentMethods(
        Array.from(
          new Set(
            this.eventPlayers
              .map((eP) => eP.stripeCustomerId || "")
              .filter((s) => s !== "")
          )
        ),
        this.merchantInfo.merchantId
      );
      if (this.$store.getters.transactions.length > 0) {
        await this.getPaymentIntents(this.$store.getters.transactions);
        await this.getPaymentMethodsForIntents(this.paymentIntents);
      }
    }
  }

  /** Unsubscribe from onSnapshot watching event paid */
  beforeDestroy() {
    if (this.eventSubscription) {
      this.eventSubscription();
    }
  }
}
